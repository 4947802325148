import { useCallback, useState } from 'react';
import axios from 'axios';
import { FormikProvider, useFormik } from 'formik';
import Cookies from 'js-cookie';
import useTranslation from 'next-translate/useTranslation';
import ButtonComponent from 'components/common/Button';
import { Modal } from 'components/common/Modal';
import Consent from 'components/Consent';
import ParagraphHeader from 'components/ParagraphHeader';
import { LeadFormProps } from 'interfaces/cms/content';
import TextBox from 'components/common/TextBox';
import Markdown from 'components/common/MarkdownV2';
import { getValidationSchemaQFlowInPage } from 'lib/cms/lgtQflowService';
import ResponseContent from 'components/ResponseContent';
import GTMHelper from 'lib/GTMHelper';
import { LGT } from 'lib/constants';
import useFetchDataLayer from 'hooks/useFetchDataLayer';

export default function LeadForm({
  campaignId,
  header,
  questions,
  button,
  agreementContent,
  tc,
}: LeadFormProps) {
  const { t } = useTranslation('common');
  const validationSchema = getValidationSchemaQFlowInPage(questions);
  const [responseStatus, setResponseStatus] = useState('');
  const [isOpenResponseModel, setIsOpenResponseModel] = useState(false);
  const [isOpenTC, setIsOpenTC] = useState(false);
  const [loading, setLoading] = useState(false);
  const refUrl = Cookies.get('lgt_referer_url');
  const cookieQuery = Cookies.get('lgt_campaign_query_params');
  const {
    data: trackingData,
    isLoading,
    isError,
  } = useFetchDataLayer(campaignId);

  const responseMappings: Record<string, { title: string; message: string }> = {
    [LGT.rejectedStatus]: {
      title: t('modal.rejected.title'),
      message: t('modal.rejected.message'),
    },
    [LGT.duplicatedStatus]: {
      title: t('modal.duplicated.title'),
      message: t('modal.duplicated.message'),
    },
    [LGT.errorStatus]: {
      title: t('modal.error.title'),
      message: t('modal.error.message'),
    },
  };

  const generateInitialValues = () => ({
    [tc.defaultConsent.code]: true,
    ...tc.consents.reduce(
      (acc: any, consent: any) => ({ ...acc, [consent.code]: false }),
      {}
    ),
  });

  const formik = useFormik({
    initialValues: tc ? generateInitialValues() : {},
    enableReinitialize: true,
    onSubmit: () => submitLead(),
    validationSchema,
  });

  const setFieldValue = useCallback(
    (field: string, value: any) => {
      formik.setFieldTouched(field);
      formik.setFieldValue(field, value);
    },
    [formik]
  );

  const submitLead = async () => {
    const requestData = {
      ...formik.values,
      campaignId: campaignId,
      lead_source: LGT.leadSource,
      queryparams: cookieQuery,
      refurl: refUrl,
    };
    try {
      const response = await axios.post(LGT.url, requestData);
      if (
        [LGT.qualifiedStatus, LGT.toCallStatus].includes(
          response.data?.lead?.nana_status
        ) &&
        !isError &&
        !isLoading
      ) {
        GTMHelper.customEvent(LGT.customEvent, trackingData);
      }
      setResponseStatus(response.data.status);
    } catch (error: any) {
      setResponseStatus(LGT.errorStatus);
    }
    setLoading(false);
    setIsOpenTC(false);
    setIsOpenResponseModel(true);
  };

  const onChangeInput = (fieldName: string, value: string | number) => {
    setFieldValue(fieldName, value);
  };

  const handleSubmit = () => {
    setLoading(true);
    submitLead();
  };

  const onCloseResponse = () => {
    setIsOpenResponseModel(false);
    setIsOpenTC(false);
    setResponseStatus('');
    formik.resetForm({ values: generateInitialValues() });
  };

  const onCloseTC = () => {
    setIsOpenTC(false);
  };

  const getResponseTitle = () =>
    responseMappings[responseStatus]?.title || t('modal.thank_you.title');

  const getResponseMessage = () =>
    responseMappings[responseStatus]?.message || t('modal.thank_you.message');

  return (
    <FormikProvider value={formik}>
      <div className="px-[0.9375rem] py-[1.875rem]">
        <div className="items-center justify-center max-w-[69.375rem] bg-[#F7F8FF] mx-auto rounded-xl p-8">
          {header && <ParagraphHeader {...header} />}
          <div className="grid grid-col-12 md:grid-cols-2">
            {questions.length &&
              questions.map((q) => (
                <TextBox
                  key={q.code}
                  {...q}
                  name={q.code}
                  type={q.type}
                  dataTestId={q.code}
                  extraClassName="px-5 py-2"
                  onChange={onChangeInput}
                />
              ))}
          </div>
          <div className="text-center pt-4">
            {button && (
              <ButtonComponent
                {...button}
                isDisabled={
                  JSON.stringify(formik?.values) ===
                    JSON.stringify(formik?.initialValues) ||
                  !!Object.keys(formik?.errors).length
                }
                onClick={() => setIsOpenTC(true)}
                btnStyle="min-w-[16.75rem] my-2"
              />
            )}
            {agreementContent && (
              <Markdown
                md={agreementContent}
                className="[&_a]:text-primary text-center py-4"
              />
            )}
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpenTC}
        onClose={onCloseTC}
        customClass="!max-w-screen-lg"
        zIndex="z-[21370002]"
      >
        <Consent
          {...tc}
          formik={formik}
          handleClick={handleSubmit}
          isLoading={loading}
        />
      </Modal>
      {isOpenResponseModel && (
        <ResponseContent
          title={getResponseTitle()}
          description={getResponseMessage()}
          status={responseStatus as any}
          isOpen={isOpenResponseModel}
          onClose={onCloseResponse}
        />
      )}
    </FormikProvider>
  );
}
