import React from 'react';
import clsx from 'clsx';
import { TextBoxProps } from 'interfaces/cms/common';
import useTranslation from 'next-translate/useTranslation';
import { useField } from 'formik';
import { formatPhoneNumber } from 'lib/cms/lgtQflowService';

function TextBox({
  inputRef,
  name,
  type, // email, text, phone
  title,
  placeholder,
  extraClassName,
  dataTestId,
  onChange,
  onInputKeyUp,
}: Readonly<TextBoxProps>) {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField(name);

  const isValid = meta.touched && !meta.error;
  const isInvalid = meta.touched && meta.error;
  const formatValueForDisplay = (value: string) =>
    type === 'phone' ? formatPhoneNumber(value) : value;

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const val = type === 'phone' ? inputValue.replace(/\D/g, '') : inputValue;
    helpers.setValue(val);
    onChange(name, val);
  };

  return (
    <div
      className={clsx(extraClassName)}
      data-testid={`textbox__section__${dataTestId}`}
    >
      {title && (
        <div className="text-textColor text-left text-[15px] leading-[1.5rem] font-[700] sm:mb-[10px] md:mb-[10px] flex">
          {title}
        </div>
      )}

      <div className="relative">
        <input
          ref={inputRef}
          type={type === 'phone' ? 'numeric' : 'text'}
          maxLength={type === 'phone' ? 12 : undefined}
          name={name}
          value={formatValueForDisplay(field.value) || ''}
          onChange={onInputChange}
          onKeyUp={onInputKeyUp}
          placeholder={placeholder || ''}
          className={clsx(
            'flex outline-none text-[#4F4B66] w-full rounded-[10px] px-[16px] pb-[14px] pt-[16px] border focus:outline-none focus:border-primaryColor',
            isInvalid &&
              'outline border-errorColor focus:border-errorColor focus:my-0 hover:border-errorColor hover:outline-none focus-visible:border-errorColor',
            isValid &&
              'outline border-outlineColor focus:border-primaryColor focus:my-0 rounded-b-0'
          )}
          data-testid={`textbox__${dataTestId}`}
        />
      </div>
      {isInvalid && (
        <div className="text-errorColor text-left text-[15px] leading-[1.5rem] sm:mb-[10px] md:mb-[10px] flex">
          {t(meta.error as string)}
        </div>
      )}
    </div>
  );
}

export default TextBox;
