import { Fragment, PropsWithChildren } from 'react';
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import clsx from 'clsx';
import CloseIcon from '@public/icons/close.svg';
import { fontFamilyMap } from 'pages/_app';
import useTranslation from 'next-translate/useTranslation';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  customClass?: string;
  zIndex?: string;
}

export function Modal({
  isOpen,
  onClose,
  children,
  customClass,
  zIndex = 'z-50',
}: Readonly<PropsWithChildren<ModalProps>>) {
  const { lang } = useTranslation();

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={clsx('fixed inset-0 overflow-y-auto text-textColor', zIndex)}
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.78)',
          backdropFilter: 'blur(2px)',
          fontFamily: lang && fontFamilyMap[lang as keyof typeof fontFamilyMap],
        }}
        onClose={onClose}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel
                className={clsx(
                  'inline-block md:p-[3.75rem] my-8',
                  'overflow-hidden text-left align-middle rounded-[1rem]',
                  'transition-all transform bg-white shadow-xl',
                  'px-0 md:px-[2.25rem]',
                  customClass
                )}
              >
                <CloseIcon
                  onClick={onClose}
                  width="30"
                  height="30"
                  viewBox="0 0 20 20"
                  className="absolute right-[0.625rem] top-[0.625rem] cursor-pointer"
                />
                {children}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

interface ModalContentProps {
  className?: string;
}

export function ModalHeader({
  className,
  children,
}: Readonly<PropsWithChildren<ModalContentProps>>) {
  return (
    <header
      className={clsx(
        className,
        'flex-[0_1_0%] py-4 px-6 text-[1.25rem] font-semibold'
      )}
    >
      {children}
    </header>
  );
}

export function ModalBody({
  className,
  children,
}: Readonly<PropsWithChildren<ModalContentProps>>) {
  return (
    <div className={clsx(className, 'flex-[1_1_0%] py-2 px-6')}>{children}</div>
  );
}

export function ModalFooter({
  className,
  children,
}: Readonly<PropsWithChildren<ModalContentProps>>) {
  return (
    <footer
      className={clsx(
        className,
        'flex items-center justify-center px-6 py-4 flex-col'
      )}
    >
      {children}
    </footer>
  );
}

export default { Modal, ModalHeader, ModalBody, ModalFooter };
