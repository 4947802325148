import { ArrowDown, HomeIcon } from '@alphafounders/icons';
import Link from 'next/link';

interface BreadcrumbItem {
  text: string;
  url: string;
}

export default function Breadcrumb({ items }: { items: BreadcrumbItem[] }) {
  if (!items || items.length === 0) {
    return null;
  }

  return (
    <nav aria-label="breadcrumb">
      <ol className="list-none p-0 inline-flex flex-wrap">
        <li className="flex items-center text-sm">
          <Link href="/">
            <HomeIcon />
          </Link>
        </li>
        {items.map((item, index) => (
          <li key={index} className="flex items-center text-sm">
            <ArrowDown className="mx-2 -rotate-90" fill="var(--placeholder)" />
            {index < items.length - 1 ? (
              <Link href={item.url}>
                <span className="text-primaryColor text-nowrap">
                  {item.text}
                </span>
              </Link>
            ) : (
              <span className="text-textColor text-nowrap">{item.text}</span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
