export const imageDimension = (width: number, height: number): string => {
  return height > width ? 'vertical' : 'horizontal';
};

/**
 * normalized rgba, hex code and postcss variables depends on input
 * check whether input was color code or not,
 * if it is, return the free text, otherwise return postcss variable
 * @param {string} value
 * @returns {string} hexCode || rgbaValue || postcss variable
 */
export const getColorCode = (value: string): string => {
  if (value.indexOf('#') > -1 || /rgb/.test(value)) {
    return value;
  }

  return `var(--${value})`;
};

export const getPathname = (url: string) => {
  if (!url) return '/'; // Handle empty or undefined input
  const pathname = url.replace(/^https?:\/\/[^/]+/, ''); // Remove protocol and domain
  return pathname.startsWith('/') ? pathname : `/${pathname}`; // Ensure leading /
};

export default {
  imageDimension,
  getColorCode,
  getPathname,
};
