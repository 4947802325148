import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import { ResponseContentProps } from 'interfaces/cms/common';
import { Modal } from 'components/common/Modal';

import Button from 'components/common/Button';
import Markdown from 'components/common/MarkdownV2';
import { LGT } from 'lib/constants';

function ResponseContent({
  title,
  description,
  status,
  isOpen,
  onClose,
}: ResponseContentProps) {
  const { t } = useTranslation('common');
  const getImagePath = () => {
    switch (status) {
      case LGT.qualifiedStatus:
        return '/images/newsletter/newsletter-thankyou.png';
      case LGT.errorStatus:
        return '/images/page-not-found.png';
      default:
        return '/images/response/rejection.png';
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        customClass="!block md:inline-block md:p-[3.75rem] my-8 overflow-hidden text-center align-middle rounded-[1rem] px-[16px] md:px-[2.25rem]"
        zIndex="z-[21370002]"
      >
        <Image
          src={getImagePath()}
          width={350}
          height={360}
          alt={`${status}-logo`}
          loading="lazy"
          className="inline-block py-[16px]"
        />
        <Markdown
          className="text-[16px] md:text-[20px] font-bold py-1"
          md={title}
        />
        <Markdown className="text-[13px] md:text-[14px]" md={description} />
        <Button
          buttonLabel={t('modal.close')}
          onClick={() => {
            onClose();
          }}
          btnStyle="my-2"
        />
      </Modal>
    </>
  );
}
export default ResponseContent;
