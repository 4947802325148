import { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { ConsentProps } from 'interfaces/cms/common';
import ButtonComponent from 'components/common/Button';
import { LoadingSpinner } from '@alphafounders/ui';
import Markdown from 'components/common/MarkdownV2';
import ConsentItem from './Item';

function Consent({
  title,
  description,
  button,
  consents,
  defaultConsent,
  formik,
  handleClick,
  isLoading = true,
}: Readonly<ConsentProps>) {
  const { t } = useTranslation();
  const [acceptAllStatus, setAcceptAllStatus] = useState(false);

  const onChangeAcceptAll = () => {
    setAcceptAllStatus(!acceptAllStatus);
    consents.forEach((consent) => {
      (async () => {
        await formik.setFieldValue(consent.code, !acceptAllStatus);
        await formik.setFieldTouched(consent.code, true, true);
      })();
    });
  };

  const generateAcceptAllCheckbox = () => {
    return (
      <ConsentItem
        dataTestId="consent-item-accept-all"
        fieldName="acceptAll"
        formik={formik}
        isDefaultSelected={acceptAllStatus}
        onChange={onChangeAcceptAll}
        label={t('common:consent.acceptAll')}
      />
    );
  };

  const onChangeConsent = (fieldName: string) => {
    const currentValue = formik.values[fieldName] || false;
    formik.setFieldValue(fieldName, !currentValue);
    formik.values[fieldName] = !currentValue;
    Object.values(formik.values).every((value) => value === true)
      ? setAcceptAllStatus(true)
      : setAcceptAllStatus(false);
  };

  return (
    <div className="p-4 md:p-0 z-21370001">
      <h2 className="text-primary text-lg font-bold">{title}</h2>
      <Markdown md={description} className="text-[#4A4A4A] text-base py-4" />
      <div className="py-4">
        {consents.length > 1 && generateAcceptAllCheckbox()}
        {consents.map((consent) => (
          <ConsentItem
            key={consent.code}
            dataTestId={`consent-item-${consent.code}`}
            fieldName={consent.code}
            label={consent.label}
            tooltip={consent.tooltip}
            formik={formik}
            onChange={() => onChangeConsent(consent.code)}
          />
        ))}
      </div>
      <div className="text-center">
        {isLoading && <LoadingSpinner small />}
        {!isLoading && (
          <ButtonComponent
            buttonLabel={button.buttonLabel}
            onClick={handleClick}
          />
        )}
      </div>
      {defaultConsent.code && (
        <Markdown
          md={defaultConsent.label}
          className="[&_a]:text-primary text-center py-4"
        />
      )}
    </div>
  );
}

export default Consent;
