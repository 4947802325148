'use client';

import Cookies from 'js-cookie';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';

export default function CookieBanner() {
  const { t } = useTranslation('layout');
  const [isAccepted, setIsAccepted] = useState(true);

  const handleOnAccept = () => {
    const now = new Date();
    const cookieValue = {
      categories: [],
      revision: 0,
      data: null,
      rfc_cookie: true,
      consent_date: now,
      last_consent_update: now,
    };
    setIsAccepted(true);
    Cookies.set('Rc_PDPA', JSON.stringify(cookieValue), { expires: 30 });
  };

  useEffect(() => {
    const rcPDPA = Cookies.get('Rc_PDPA');
    setIsAccepted(!!rcPDPA);
  }, []);

  const [beforeLink, afterLink] = t('cookie.consent').split(
    t('cookie.linkText')
  );

  return (
    !isAccepted && (
      <div className="fixed bottom-0 w-full flex justify-center z-50 p-3">
        <div className="flex flex-col md:flex-row bg-white py-[20px] px-[1rem] max-w-[46rem] rounded-[16px] shadow-[0px_7px_15px_rgba(42,49,203,0.2)]">
          <div className="text-sm leading-[21px]">
            <div className="text-left font-size-sm">
              {beforeLink}
              <a className="text-primary" target="_blank" href="/cookie-policy">
                {t('cookie.linkText')}
              </a>
              {afterLink}
            </div>
          </div>
          <button
            onClick={handleOnAccept}
            className="md:min-w-[7rem] md:ml-[1rem] mt-[1rem] md:mt-0 font-bold uppercase bg-buttonPrimaryBg hover:bg-buttonPrimaryBgHover text-white rounded-[10px] py-[13px] px-[27px] text-sm h-[50px]"
          >
            {t('cookie.btnAccept')}
          </button>
        </div>
      </div>
    )
  );
}
