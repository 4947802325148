import React, { BaseSyntheticEvent, ReactElement } from 'react';
import clsx from 'clsx';
import { ConsentItemProps } from 'interfaces/cms/common';
import { HelpTool } from '@alphafounders/ui';
import Markdown from 'components/common/MarkdownV2';
import 'react-tippy/dist/tippy.css';

function ConsentItem({
  dataTestId,
  tooltip,
  extraClasses,
  fieldName,
  label,
  formik,
  isDefaultSelected = false,
  onChange,
}: Readonly<ConsentItemProps>): ReactElement {
  const errorMessage =
    formik?.errors[fieldName] && formik?.touched[fieldName]
      ? formik?.errors[fieldName]
      : '';
  const selected = formik?.values[fieldName] || isDefaultSelected;

  const handleTooltipClick = (e: BaseSyntheticEvent) => {
    e.stopPropagation();
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleTooltipClick(event);
    }
  };

  return (
    <div className="flex">
      <label
        className={clsx(
          'inline-flex relative pl-[25px] mb-[12px] cursor-pointer text-[16px] font-normal select-none',
          extraClasses
        )}
        htmlFor={fieldName}
      >
        <input
          type="checkbox"
          data-testid={`${dataTestId}-item-${fieldName}`}
          id={fieldName}
          name={fieldName}
          value={fieldName}
          className="absolute cursor-pointer opacity-0 h-0 w-0"
          checked={selected}
          onChange={() => onChange(fieldName)}
        />
        <span
          className={clsx([
            'absolute left-0 h-[25px] w-[25px] rounded-[4px] border-[0.5px] border-[#005098] border-solid',
            selected
              ? 'border-[#005098] text-white hover:bg-[#005098] bg-[#005098] bg-checkbox-icon bg-center bg-[size:50%_50%] bg-no-repeat ml-auto'
              : 'bg-[#fff]',
          ])}
        />
        <div
          className={clsx(
            'ml-[8px] text-left',
            'flex-col justify-start items-start'
          )}
        >
          <div className="justify-end items-start">
            <Markdown
              md={label}
              className="[&_a]:overflow-anywhere [&_a]:text-primary"
            />
          </div>

          <div className="text-errorColor text-left">
            {errorMessage as string}
          </div>
        </div>
      </label>
      {tooltip && (
        <div
          className="relative ml-auto"
          onClick={handleTooltipClick}
          role="button"
          data-testid={`tooltip-${fieldName}`}
          onKeyDown={handleKeyDown}
          tabIndex={0}
        >
          <HelpTool tip={tooltip} position="bottom-end" />
        </div>
      )}
    </div>
  );
}

export default ConsentItem;
