import dynamic from 'next/dynamic';
import { DynamicContentProps, DynamicContentTypes } from 'types/cms';
import BiographyNew from './BiographyNew';
import BlogsTab2 from './BlogsTab2';
import ProductCardsListing from './ProductCardsListing';
import LeadForm from './LeadForm';

const LinkBuilding = dynamic(() => import('./LinkBuilding'));
const NewsLetter = dynamic(() => import('./Newsletter'));
const Table = dynamic(() => import('./Table'));
const SliderWithCta = dynamic(() => import('./SliderWithCta'));
const SlideShow = dynamic(() => import('./SlideShow'));
const FaqAndSeo = dynamic(() => import('./FaqAndSeo'));
const FaqAndSeoTab = dynamic(() => import('./FaqAndSeoTab'));
const CustomerReviews = dynamic(() => import('./CustomerReviews'));
const ParagraphAndImage = dynamic(() => import('./ParagraphAndImage'));
const ProductListingModule = dynamic(() => import('./ProductListingModule'));
const Blogs = dynamic(() => import('./Blogs'));
const BlogsTab = dynamic(() => import('./BlogsTab'));
const MobileFirstBanner = dynamic(() => import('./MobileFirstBanner'));
const Video = dynamic(() => import('./Video'));
const ProductListingMoreColumns = dynamic(
  () => import('./ProductListingMoreColumns')
);
const ImpactText = dynamic(() => import('./ImpactText'));
const Category = dynamic(() => import('./Category'));
const ProductOverview = dynamic(() => import('./ProductOverview'));
const TextBox = dynamic(() => import('./TextBox'));
const ShortQuestionnaire = dynamic(() => import('./ShortQuestionnaire'));
const Logo = dynamic(() => import('./Logo'));
const BannerInPageForm = dynamic(() => import('./BannerInPageForm'));
const LoanWizard = dynamic(() => import('./LoanWizard'));
const MapAndTextBox = dynamic(() => import('./MapAndTextBox'));
const Biography = dynamic(() => import('./Biography'));
const Breadcrumb = dynamic(() => import('./Breadcrumb'));
const YouMayAlsoLike = dynamic(() => import('./YouMayAlsoLike'));
const LoanWizardTextInput = dynamic(() => import('./LoanWizardTextInput'));
const ProductListingAndTabs = dynamic(() => import('./ProductListingAndTabs'));

function DynamicContent({
  content,
}: {
  readonly content: DynamicContentProps[];
}) {
  const getComponent = (component: DynamicContentProps, idx: number) => {
    switch (component.componentType) {
      case DynamicContentTypes.LINKBUILDING:
        return <LinkBuilding key={component.id} {...component} />;
      case DynamicContentTypes.PARAGRAPHIMAGE:
        return (
          <ParagraphAndImage
            key={component.id}
            {...component}
            priority={idx <= 1}
          />
        );
      case DynamicContentTypes.NEWSLETTER:
        return <NewsLetter key={component.id} {...component} />;
      case DynamicContentTypes.TABLE:
        return <Table key={component.id} {...component} />;
      case DynamicContentTypes.SLIDERWITHCTA:
        return (
          <SliderWithCta
            key={component.id}
            {...component}
            priority={idx <= 1}
          />
        );
      case DynamicContentTypes.SLIDESHOW:
        return <SlideShow key={component.id} {...component} />;
      case DynamicContentTypes.FAQSEO:
        return <FaqAndSeo key={component.id} {...component} />;
      case DynamicContentTypes.FAQSEOTAB:
        return <FaqAndSeoTab key={component.id} {...component} />;
      case DynamicContentTypes.REVIEWS:
        return <CustomerReviews key={component.id} {...component} />;
      case DynamicContentTypes.PRODUCTLISTING:
        return <ProductListingModule key={component.id} {...component} />;
      case DynamicContentTypes.BLOGS:
        return <Blogs key={component.id} {...component} />;
      case DynamicContentTypes.BLOGSTAB:
        return <BlogsTab key={component.id} {...component} />;
      case DynamicContentTypes.BLOGSTAB2:
        return <BlogsTab2 key={component.id} {...component} />;
      case DynamicContentTypes.VIDEO:
        return <Video key={component.id} {...component} />;
      case DynamicContentTypes.MOBILEFIRSTBANNER:
        return (
          <MobileFirstBanner
            key={component.id}
            {...component}
            priority={idx <= 1}
          />
        );
      case DynamicContentTypes.PRODUCTLISTINGTWOCOLUMNS:
        return <ProductListingMoreColumns key={component.id} {...component} />;
      case DynamicContentTypes.IMPACTTEXT:
        return <ImpactText key={component.id} {...component} />;
      case DynamicContentTypes.CATEGORY:
        return <Category key={component.id} {...component} />;
      case DynamicContentTypes.PRODUCTOVERVIEW:
        return <ProductOverview key={component.id} {...component} />;
      case DynamicContentTypes.TEXTBOX:
        return <TextBox key={component.id} {...component} />;
      case DynamicContentTypes.SHORTQUESTIONNAIRE:
        return <ShortQuestionnaire key={component.id} {...component} />;
      case DynamicContentTypes.LOGOS:
        return <Logo key={component.id} {...component} />;
      case DynamicContentTypes.BANNERINPAGEFORM:
        return <BannerInPageForm key={component.id} {...component} />;
      case DynamicContentTypes.LOANWIZARD:
        return <LoanWizard key={component.id} {...component} />;
      case DynamicContentTypes.MAPANDTEXTBOX:
        return <MapAndTextBox key={component.id} {...component} />;
      case DynamicContentTypes.BIOGRAPHY:
        return <Biography key={component.id} {...component} />;
      case DynamicContentTypes.BREADCRUMB:
        return <Breadcrumb key={component.id} {...component} />;
      case DynamicContentTypes.YOUMAYALSOLIKE:
        return <YouMayAlsoLike key={component.id} {...component} />;
      case DynamicContentTypes.LOANWIZARDTEXTINPUT:
        return <LoanWizardTextInput key={component.id} {...component} />;
      case DynamicContentTypes.PRODUCTLISTINGANDTABS:
        return <ProductListingAndTabs key={component.id} {...component} />;
      case DynamicContentTypes.BIOGRAPHYNEW:
        return <BiographyNew key={component.id} {...component} />;
      case DynamicContentTypes.PRODUCTCARDSLISTING:
        return <ProductCardsListing key={component.id} {...component} />;
      case DynamicContentTypes.LEADFORM:
        return <LeadForm key={component.id} {...component} />;
      default:
        return null;
    }
  };

  return (
    <>
      {content.map((component: DynamicContentProps, idx) => {
        const uniqueId = `${component.componentType}-${component.id}`;
        return (
          <section id={uniqueId} key={uniqueId}>
            {getComponent(component, idx)}
          </section>
        );
      })}
    </>
  );
}

export default DynamicContent;
