import axios from 'axios';
import useSWR, { Fetcher } from 'swr';
import { DataLayer, DataLayerResponse } from 'pages/api/get-datalayer';

type Response = {
  data: DataLayer | undefined;
  isLoading: boolean;
  isError: boolean;
};

const fetcher: Fetcher<DataLayerResponse, string> = (url: string) =>
  axios.get(url).then((res) => res.data);

const toKebabCase = (str: string) =>
  str
    .replace(/_/g, '-')
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .toLowerCase()
    .replace(/\b[a-z]/g, (c) => c.toUpperCase());

const convertKeysToKebab = (obj: any): DataLayer =>
  obj && typeof obj === 'object'
    ? Object.fromEntries(
        Object.entries(obj).map(([k, v]) => [
          toKebabCase(k),
          convertKeysToKebab(v),
        ])
      )
    : obj;

export default function useFetchDataLayer(campaignId: string): Response {
  const { data, error, isValidating } = useSWR<DataLayerResponse, Error>(
    campaignId ? `/api/get-datalayer?campaignId=${campaignId}` : null,
    fetcher,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );

  return {
    data: convertKeysToKebab(data?.data),
    isLoading: (!error && !data) || isValidating,
    isError: !!error,
  };
}
